import {RouteLocationNormalized} from "vue-router";
import {defineNuxtRouteMiddleware} from '#app';

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized) => {
  // console.log(to.params.id);
  const {workflow} = useMarketplaceMatching();
  // if (!workflow.value?.id) {
  //   const localePath = useLocalePath();
  //   return navigateTo(localePath({path: `/matching`}));
  // }
})
